import { 
  mapGetters,
  mapState 
} from 'vuex'

export default {
  computed: {
    ...mapState({
      cart: state => state['sayl-front.cart'].cart,
      _service: state => state['sayl-front.service'].service,
      isMultiShop: state => state.bootstrap.isMultiShop,
      _state: state => state['sayl-front.catalog.multishop'],
    }),

    ...mapGetters({
      shopOpened: 'sayl-front/shopOpened'
    }),

    cartedShops() {
      return this.subshops && this.cart && this.subshops.filter((s) => {
        return this.cart.shops[s.id] != null && this.cart.shops[s.id].length > 0
      })
    },

    hasItems() {
      return this.cart && this.cart.items && this.cart.items.length > 0
    },

    hasItemsFromClosedShop() {
      if(this.subshops && this.isMultiShop && this.hasItems && this.hasShops) {
        return this.cartedShops.filter(s => this.isClosed(s) === true).length > 0
      }

      return false
    },

    hasShops() {
      return this.isMultiShop && this.cart && Object.keys(this.cart?.shops ?? {}).length > 0
    },

    isEnabled() {
      if(!this.isMultiShop) {
        return [
          !this.shopOpened.value && !this.isCatalogReadonly, // Shop closed
          this.shopOpened.value && !this.hasFlPos(this.shop) && !this.isCatalogReadonly, // FLPOS error
          this.shopOpened.value && this.hasFlPos(this.shop) && this.workloadClosed(this.shop) && !this.isCatalogReadonly, // Workload is closed
        ].find(s => s === true) == null
      }

      return this.isShopOpen && this.hasFlPos(this.shop) && !this.hasItemsFromClosedShop && !this.isClosed(this.baseShop)
    },

    isCatalogReadonly() {
      return this.$basil.get(this.oat, 'is_catalog_readonly', false)
    },

    isShopOpen() {
      return !!this.shopOpened.value
    },

    subshops() {
      return this.$basil.get(this._state, 'shops')
    },
  },

  methods: {
    hasFlPos(shop) {
      let service = this.$basil.get(this.cart, 'service', this.$basil.get(this._service, 'apiname'))
      
      if(service == null) {
        return true
      }

      service = service === 'base' ? 'pickup' : service
      let ret = this.$basil.get(shop, 'flposes', [])
      ret = ret.filter(s => s.services.includes(service))

      return ret.length > 0
    },

    isClosed(shop) {
      let ret = false 
      let service = this.$basil.get(this.cart, 'service', this.$basil.get(this._service, 'apiname'))

      if(service && shop.services) {
        let s = shop.services.find(s => s.apiname === service)
        if(s) {
          ret = !s.isOpen
        }
      }

      return [
        ret,
        this.workloadClosed(shop),
        !this.hasFlPos(shop)
      ].find(s => s === true) != null
    },

    workloadClosed(shop) {
      let ret = this.$basil.get(shop, 'flposes', [])
      ret = ret.filter(s => s.workload !== 'closed')

      return ret.length === 0
    },
  },

  async mounted() {
    if(!this.subshops && this.isMultishop) {
      let shops = await this.$embed.shop.getShops({ childrenOnly: 1, locale: this.i18n.locale.iso || 'en' })
      this.$store.commit('sayl-front.catalog.multishop/setShops', shops)
    }
  }
}