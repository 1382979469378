<template>
  <main>
    <!-- Intro -->
    <catalog-shop-intro
      :size="size"
      :loading="!shop"
      :shop="shop"
    />

    <div
      class="view-catalog-shop__loader flow"
      v-if="langLoading">
      <ui-loader />
    </div>

    <!-- Views -->
    <router-view
      :key="service"
      v-if="!langLoading"
    />
  </main>
</template>

<script>
import CatalogShopIntro from '@/modules/catalog/components/catalog/intro'
import MixinMultishop from '@/helpers/multishop'

export default {
  name: 'CatalogShopView',

  components: {
    CatalogShopIntro
  },

  inject: [
    '$embed',
    '$catalog'
  ],

  mixins: [
    MixinMultishop,
  ],

  data() {
    return {
      langLoading: false
    }
  },

  computed: {
    isChallengeEnabled() {
      return this.$basil.get(this.oat, 'is_challenge_enabled', false)
    },

    shop() {
      return this.subshop ? this.subshop : this.$basil.get(this.$embed, 'shop.model')
    },

    size() {
      return this.service ? 's' : 'm'
    },
  },

  methods: {
    async reset(force = false) {
      if(!this.subshops || force === true) {
        let shops = await this.$embed.shop.getShops({ childrenOnly: 1, locale: this.i18n.locale.iso || 'en' })
        this.$store.commit('sayl-front.catalog.multishop/setShops', shops)
      }

      if(this.$route.params.slug) {
        let shop = this.subshops.find(shop => shop.slug === this.$route.params.slug)
        if(!shop) {
          this.$router.push({ name: 'sayl-front-catalog.shops' })
          return
        }
        await this.$store.dispatch('sayl-front.catalog.multishop/setShop', { locale: this.i18n.locale.iso || 'en', service: this.service, shop: shop, $core: this.$core, force })
      } else if(force) {
        this.$store.dispatch('sayl-front.catalog.multishop/setShop', { locale: this.i18n.locale.iso || 'en', service: this.service, shop: null, $core: this.$core, force })
      }

      if(this.isChallengeEnabled) {
        this.$challenge.getChallenges()
          .catch((e) => $console.error(e))
      }

      this.langLoading = false
    }
  },

  created() {
    this.reset()

    this.$bus.$on('change-lang', () => {
      this.langLoading = true
      this.reset(true)
    })
  },

  beforeDestroy() {
    this.$bus.$off('change-lang')
  }
}
</script>
