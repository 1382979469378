<template>
  <section
    :class="classes"
    v-if="!loading" >

    <div class="catalog-intro__container">
      <!-- Illustration -->
      <article class="catalog-intro__illustration">
        <ui-hero
          :allow-multiple="false"
          :figures="pictures"
          fit="cover"
          v-if="hasPictures"
          v-scroll-reveal
        />

        <div 
          class="catalog-intro__logo" 
          v-if="isMultiShop && subshop && $basil.get(subshop, 'logo', null) != null">
          <ui-img :src="subshop.logo" />
        </div>
      </article>

      <!-- Body -->
      <article
        class="catalog-intro__content"
        :class="{ '-fill': showMore }"
        ref="content">
        <div class="catalog-intro__header">
          <h1
            class="catalog-intro__title"
            v-scroll-reveal
          >{{ shop.name }}</h1>

          <data-tag
            class="catalog-intro__tag"
            hue="red"
            v-if="isInnerClosed"
            v-scroll-reveal
          >{{ $t(`resto.closed`) }}</data-tag>
        </div>

        <div class="catalog-intro__body" >
          <ul
            class="catalog-intro__meta"
            v-scroll-reveal>
            <!-- Address -->
            <li
              class="catalog-intro__metaitem -location"
              v-if="shop.address">
              <ui-icon glyph="location"/>
              {{ shop.address }}
            </li>

            <!-- Phone number -->
            <li
              class="catalog-intro__metaitem -phone"
              v-if="shop.phone">
              <ui-icon glyph="phone"/>
              <a :href="phoneLink">{{ shop.phone | phonenumber }}</a>
            </li>

            <!-- Table number -->
            <li
              class="catalog-intro__metaitem -table"
              :key="key"
              v-if="isEatin && table">
              <ui-icon glyph="eatin" />
              {{ $t('resto.table_number') }}{{ tableNumber }}
            </li>

            <!-- Description -->
            <li
              class="catalog-intro__description"
              v-if="shop.description"
              v-html="nl2br(shop.description)"
              v-scroll-reveal
            ></li>

            <!-- Opening hours -->
            <li
              class="catalog-intro__metaitem column -opening"
              v-if="service && service.openingHours">
              <ui-icon glyph="time"/>
              <span v-html="nl2br(service.openingHours)"></span>
            </li>
          </ul>
        </div>

        <footer
          class="catalog-intro__footer"
          v-if="footer.length > 0 && !disableNavigation">
          <div
            class="intro-footer-action"
            :key="f.title"
            @click="goTo(f.href)"
            v-for="f in footer">
            <div class="intro-footer-action__illustration">
              <ui-icon
                class="intro-footer-action__icon"
                :glyph="f.icon"
                v-if="f.icon"
              />
            </div>

            <div class="intro-footer-action__content">
              <h4 class="intro-footer-action__title">{{ f.title }}</h4>
              <p class="intro-footer-action__description">{{ f.description }}</p>
            </div>

            <ui-icon
              class="intro-footer-action__link"
              glyph="angle-right"
              v-if="f.href"
            />
          </div>
        </footer>

        <!-- Mobile::Overflow -->
        <div
          class="catalog-intro__overflow overflow"
          :class="{ '-open': showMore }"
          v-if="!lockMore">
          <div
            class="overflow__icons"
            v-if="!showMore && !disableNavigation">
            <div
              class="overflow__icon"
              @click="goTo({ name: 'sayl-front-user.balance' })"
              v-if="hasLoyalty">
              <ui-icon
                glyph="award"
                :title="$t('resto.loyalty_program_available')"
              />
            </div>

            <div
              class="overflow__icon"
              @click="goTo({ name: 'sayl-front-user.challenges' })"
              v-if="hasChallenges">
              <ui-icon
                glyph="flag"
                :title="$t('resto.challenges_available')"
              />
            </div>
          </div>

          <actions-button
            :appearance="$pepper.Appearance.SUBTLE"
            class="overflow__action"
            :icon-post="showMore ? 'caret-up' : 'caret-down'"
            :size="$pepper.Size.S"
            @click="showMore = !showMore"
          >{{ showMore ? $t('resto.show_less') : $t('resto.show_more') }}</actions-button>
        </div>
      </article>
    </div>

    <!-- Mobile::Footer:Tablet -->
    <footer
      class="catalog-intro__footer -tablet"
      v-if="footer.length > 0 && !disableNavigation">
      <div
        class="intro-footer-action"
        :key="f.title"
        @click="goTo(f.href)"
        v-for="f in footer">
        <div class="intro-footer-action__illustration">
          <ui-icon
            class="intro-footer-action__icon"
            :glyph="f.icon"
            v-if="f.icon"
          />
        </div>

        <div class="intro-footer-action__content">
          <h4 class="intro-footer-action__title">{{ f.title }}</h4>
          <p class="intro-footer-action__description">{{ f.description }}</p>
        </div>

        <ui-icon
          class="intro-footer-action__link"
          glyph="angle-right"
          v-if="f.href"
        />
      </div>
    </footer>
  </section>

  <section
    class="catalog-intro -is-loading"
    v-else>
    <ui-loader />
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import MixinMultishop from '@/helpers/multishop'
import MixinOrderable from '@/helpers/orderable'
import MixinText from '@/helpers/text'

import UiHero from '@/modules/catalog/components/ui/hero'
import UiImg from '@/components/ui/img'

export default {
  name: 'CatalogShopIntro',

  components: {
    UiHero, 
    UiImg,
  },

  mixins: [
    MixinMultishop,
    MixinOrderable,
    MixinText
  ],

  inject: [
    '$challenge',
    '$embed',
    '$localStorage',
    '$loyalty',
  ],

  props: {
    size: {
      type: String,
      default: 'm'
    }
  },

  data() {
    return {
      key: 0,
      lockMore: true,
      showMore: false,
    }
  },

  computed: {
    ...mapState({
      service: state => state['sayl-front.service'].service,
      table: state => state['sayl-front.service-eatin'].table,
    }),

    ...mapGetters({
      shopOpened: 'sayl-front/shopOpened'
    }),

    classes() {
      return {
        'catalog-intro': true,
        '-s': this.size === 's'
      }
    },

    disableNavigation() {
      return this.$route.name === 'sayl-front-catalog.b2b'
    },

    footer() {
      return [
        this.hasLoyalty ? {
          description: this.$t('resto.catalog_intro_loyalty_description'),
          href: { name: 'sayl-front-user.balance' },
          icon: 'award',
          title: this.$t('resto.catalog_intro_loyalty'),
        } : null,

        this.hasChallenges ? {
          description: this.$t('resto.catalog_intro_challenges_description'),
          href: { name: 'sayl-front-user.challenges' },
          icon: 'flag',
          title: this.$t('resto.catalog_intro_challenges'),
        } : null
      ].filter(f => !this.$basil.isNil(f))
    },

    isCatalogReadonly() {
      return this.$basil.get(this.oat, 'is_catalog_readonly', false)
    },

    isInnerClosed() {
      if(!this.isMultiShop) {
        return [
          !this.shopOpened.value && !this.isCatalogReadonly, // Shop closed
          this.shopOpened.value && !this.hasFlPos(this.shop) && !this.isCatalogReadonly, // FLPOS error
          this.shopOpened.value && this.hasFlPos(this.shop) && this.workloadClosed(this.shop) && this.isPickup && !this.isCatalogReadonly, // Workload is closed
        ].find(s => s === true) != null && this.$route.name !== 'sayl-front-catalog.services'
      }

      return this.isClosed(this.shop) && this.$route.name !== 'sayl-front-catalog.services'
    },

    isEatin() {
      return this.service && this.service.name === 'eatin'
    },

    isModeTt() {
      return this.$localStorage.mode === 'tt' && this.$localStorage.service === 'eatin'
    },

    isPickup() {
      return this.service && this.service.name === 'pickup'
    },

    hasChallenges() {
      let ret = this.$basil.get(this.oat, 'is_challenge_enabled', false)
      if(ret) {
        ret = this.$basil.get(this.$challenge, 'challenges.length', 0) > 0
      }
      return ret && !this.isModeTt
    },

    hasLoyalty() {
      let excludedShopIds = this.$basil.get(this.$loyalty, 'program.excludedShopIds', [])
      let isShopExcluded = false
      
      if(!this.$basil.isNil(excludedShopIds) && !this.$basil.isEmpty(excludedShopIds)) {
        isShopExcluded = !this.$basil.isNil(excludedShopIds.find(e => e === this.$basil.get(this.$embed, 'shop.model.id')))
      }

      return [
        !this.$basil.isNil(this.$basil.get(this.$loyalty, 'program', null)),
        !this.isModeTt,
        !isShopExcluded
      ].filter(r => r === false).length === 0
    },

    hasPictures() {
      return this.pictures && this.pictures.length > 0
    },

    loading() {
      return this.$embed.loading
    },

    pictures() {
      let ret = this.shop.banners || []
      return ret
    },

    phoneLink() {
      return `tel://${this.shop.phone}`
    },

    tableNumber() {
      return this.$basil.get(this.table, 'name', null)
    },
  },

  methods: {
    goTo(href) {
      this.$router.push(href)
    },

    resize() {
      let ref = this.$refs.content
      let height = ref.getBoundingClientRect().height

      if(height >= (15 * 16)) {
        this.lockMore = false
      }
    }
  },

  mounted() {
    this.resize()
    window.addEventListener('resize', this.resize)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  }
}
</script>
